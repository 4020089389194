import { DOMAINS } from "services";
import _ from "lodash";

export const DOMAINS_STATE = {
    SET_DOMAINS_ACTIVE_TAB: 'SET_DOMAINS_ACTIVE_TAB',
    SET_LIST_DOMAINS_PAGE: 'SET_LIST_DOMAINS_PAGE',
    SET_LIST_DOMAINS_PAGE_SIZE: 'SET_LIST_DOMAINS_PAGE_SIZE',
    GET_LIST_DOMAINS: 'GET_LIST_DOMAINS',
    GET_LIST_DOMAINS_SUCCESS: 'GET_LIST_DOMAINS_SUCCESS',
    GET_LIST_DOMAINS_FAILED: 'GET_LIST_DOMAINS_FAILED',
    GET_ORGANIZATION_LIST: 'GET_ORGANIZATION_LIST',
    GET_ORGANIZATION_LIST_SUCCESS: 'GET_ORGANIZATION_LIST_SUCCESS',
    GET_ORGANIZATION_LIST_FAILED: 'GET_ORGANIZATION_LIST_FAILED',
    GET_TYPES_LIST: 'GET_TYPES_LIST',
    GET_TYPES_LIST_SUCCESS: 'GET_TYPES_LIST_SUCCESS',
    GET_TYPES_LIST_FAILED: 'GET_TYPES_LIST_FAILED',
    UPDATE_DOMAINS: 'UPDATE_DOMAINS',
    UPDATE_DOMAINS_SUCCESS: 'UPDATE_DOMAINS_SUCCESS',
    UPDATE_DOMAINS_FAILED: 'UPDATE_DOMAINS_FAILED',
    UPDATE_DOMAINS_CLEANUP: 'UPDATE_DOMAINS_CLEANUP'
};

export function setActiveTab(tab) {
    return {
        type: DOMAINS_STATE.SET_DOMAINS_ACTIVE_TAB,
        tab
    };
};

export const getListDomains = (params) => {
    return dispatch => {
        dispatch(fetchListDomains());//isLoading = true
        DOMAINS.getListDomains(params).then(response => {
            const { data } = response;
            if (data.success) {
                dispatch(fetchListDomainsSuccess(data.data));//isLoading = false
            }
        }).catch(error => {
            dispatch(fetchListDomainsFailed(error));//isLoading = false
        });
    };
};

//using organization services, get the organizations list
export const getListOrganization = (params) => {
    return dispatch => {
        dispatch(fetchOrganizationList());
        //avoid repeating same code, import existing code
        DOMAINS.getOrganizationList(params).then(response => {
            const { data } = response;
            if (data.meta.status) {
                //convert the response to the proper format for react-select options
                let orgList = [];
                let tmpOrgList = [];
                orgList.push({
                    label: 'Discard',
                    value: 'discard'
                });
                orgList.push({
                    label: 'Unknown',
                    value: 'unknown'
                });

                data.data.data.map(e => {
                    tmpOrgList.push({
                        label: e.name,
                        value: e.id,
                        external_id: e.external_id
                    });
                });

                tmpOrgList.sort((a, b) => {
                    return a.label.localeCompare(b.label);
                });
                orgList = [...orgList, ...tmpOrgList];
                dispatch(fetchOrganizationListSuccess(orgList));
            }
        }).catch(error => {
            dispatch(fetchOrganizationListFailed(error));
        });
    };
};

export function fetchOrganizationList() {
    return {
        type: DOMAINS_STATE.GET_ORGANIZATION_LIST
    };
}

export function fetchOrganizationListSuccess(orgList) {
    return {
        type: DOMAINS_STATE.GET_ORGANIZATION_LIST_SUCCESS,
        orgList
    };
}

export function fetchOrganizationListFailed() {
    return {
        type: DOMAINS_STATE.GET_ORGANIZATION_LIST_FAILED
    };
}

export const fetchListDomains = () => {
    return {
        type: DOMAINS_STATE.GET_LIST_DOMAINS
    };
};

export const fetchListDomainsFailed = () => {
    return {
        type: DOMAINS_STATE.GET_LIST_DOMAINS_FAILED
    };
};

export const fetchListDomainsSuccess = (data) => {
    return {
        type: DOMAINS_STATE.GET_LIST_DOMAINS_SUCCESS,
        data
    };
};

export function setListDomainsPage(page) {
    return {
        type: DOMAINS_STATE.SET_LIST_DOMAINS_PAGE,
        page
    };
}

export function setListDomainsPageSize(pageSize) {
    return {
        type: DOMAINS_STATE.SET_LIST_DOMAINS_PAGE_SIZE,
        pageSize
    };
}

export const getTypesList = (params) => {
    return async (dispatch) => {
        dispatch(fetchTypesList());
        await DOMAINS.getTypesList({
            ...params,
            parts: 'promo,basic'
        }).then(response => {
            const { data } = response;
            if (data.meta.status) {
                let types = [];
                types.push({
                    value: 'unknown',
                    label: 'Unknown'
                });
                data.data.data.map(type => {
                    types.push({
                        value: type.type, label: type?.front_view ?? _.startCase(_.camelCase(type.type))
                    });
                });
                dispatch(fetchTypesListSuccess(types));
            } else {
                dispatch(fetchTypesListFailed());
            }
        }).catch(error => {
            dispatch(fetchTypesListFailed());
        });
    };
};

export const fetchTypesList = () => {
    return {
        type: DOMAINS_STATE.GET_TYPES_LIST
    };
};

export const fetchTypesListSuccess = (types) => {
    return {
        type: DOMAINS_STATE.GET_TYPES_LIST_SUCCESS,
        data: types
    };
};

export const fetchTypesListFailed = () => {
    return {
        type: DOMAINS_STATE.GET_TYPES_LIST_FAILED
    };
};

export const updateDomains = (params, data) => {
    return (dispatch) => {
        dispatch(postUpdateDomains());
        DOMAINS.updateDomains(params,data).then(response=>{
            dispatch(postUpdateDomainsSuccess());
        }).catch(error=>{
            dispatch(postUpdateDomainsFailed());
        });
    };
};

export function postUpdateDomains() {
    return {
        type: DOMAINS_STATE.UPDATE_DOMAINS
    };
}

export function postUpdateDomainsSuccess() {
    return {
        type: DOMAINS_STATE.UPDATE_DOMAINS_SUCCESS
    };
}

export function postUpdateDomainsFailed(errorMessage) {
    return {
        type: DOMAINS_STATE.UPDATE_DOMAINS_FAILED,
        err: errorMessage && errorMessage!=='' ? errorMessage : 'Something went wrong'
    };
}

//set all success state to false
export function updateDomainsCleanup(){
    return {
        type: DOMAINS_STATE.UPDATE_DOMAINS_CLEANUP
    };
}