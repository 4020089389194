import AuthReducer from "pages/Login/reducer";
import OrganizationReducer from "pages/Organization/reducer";
import WorkflowsReducer from "pages/DataWorkflows/reducer";
import reduxDefault from "./../redux/reducers";
import DomainsReducer from 'pages/domains/reducer';

// List all the reducer, for combining needed
const rootReducer = {
	AuthReducer,
	OrganizationReducer,
	WorkflowsReducer,
	DomainsReducer,
	...reduxDefault
};

export default rootReducer;