import axios from 'axios';
import { Service } from 'config';

export const getListDomains = (params) => {
  let {
    token,
    ...rest
  } = params;
  return axios.get(`${Service.API_SERVICE}/domains`, {params});
};

export const getOrganizationList = (params) => {
  let {
    token,
    ...rest
  } = params;
  return axios.get(`${Service.CONTENT_API}/organisations`,{
    params:rest
  });
};

export const updateDomains = (params,data) => {
  let {
    token
  } = params;
  return axios.post(`${Service.API_SERVICE}/domains`,data,);
};

export const getTypesList = (params) => {
  const {
    token,
    ...rest
  } = params;

  return axios.get(`${Service.CONTENT_API}/creatives/types`,{
    params: rest,
  });
};