import { DOMAINS_STATE } from './action'; //action types 'enum'

//initial state values
const initialState = {
    activeTab: 1,

    isLoading: false,
    isError: false,
    errorMessage: null,

    domains: [],
    totalDomainsData: 0,
    totalDomainsPerPage: 10,
    currentDomainsPage: 1,
    lastDomainsPage: 1,

    //states for organization list
    isLoadingOrgList: false,
    isErrorOrgList: false,
    errorMessageOrgList: null,
    orgList: [],

    //types list
    isLoadingTypeList: false,
    isErrorTypeList: false,
    errorMessageTypeList: null,
    types: [],

    //updating edited domains
    isLoadingUpdate: false,
    isErrorUpdate: false,
    isSuccessUpdate: false,
    updateErrorMessage: null
};

//reducer returns the next state values given a specific action
const DomainsReducer = (state = initialState, action) => {
    switch (action.type) {
        case DOMAINS_STATE.SET_DOMAINS_ACTIVE_TAB:
            return {
                ...state,
                activeTab: action.tab
            };
        case DOMAINS_STATE.SET_LIST_DOMAINS_PAGE:
            return {
                ...state,
                currentDomainsPage: action.page
            };
        case DOMAINS_STATE.SET_LIST_DOMAINS_PAGE_SIZE:
            return {
                ...state,
                totalDomainsPerPage: action.pageSize
            };
        case DOMAINS_STATE.GET_LIST_DOMAINS:
            return {
                ...state,
                isLoading: true,
                isError: false,
                errorMessage: null,
            };
        case DOMAINS_STATE.GET_LIST_DOMAINS_FAILED:
            return {
                ...state,//keep other state previous values
                isLoading: false,
                isError: true,
                errorMessage: action?.data?.message ?? 'Something went wrong',
            };
        case DOMAINS_STATE.GET_LIST_DOMAINS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                errorMessage: null,
                domains: action.data.data,
                totalDomainsData: Number(action.data.total),
                totalDomainsPerPage: Number(action.data.per_page),
                currentDomainsPage: Number(action.data.current_page),
                lastDomainsPage: Number(action.data.last_page)
            };
        case DOMAINS_STATE.GET_ORGANIZATION_LIST:
            return {
                ...state,
                isLoadingOrgList: true,
                isErrorOrgList: false,
                errorMessageOrgList: null
            };
        case DOMAINS_STATE.GET_ORGANIZATION_LIST_FAILED:
            return {
                ...state,
                isLoadingOrgList: false,
                isErrorOrgList: true,
                errorMessageOrgList: "Cannot fetch organization list"
            };
        case DOMAINS_STATE.GET_ORGANIZATION_LIST_SUCCESS:
            return {
                ...state,
                isLoadingOrgList: false,
                isErrorOrgList: false,
                errorMessageOrgList: null,
                orgList: action.orgList
            };
        case DOMAINS_STATE.GET_TYPES_LIST:
            return {
                ...state,
                isLoadingTypeList: true,
                isErrorTypeList: false,
                errorMessageTypeList: null
            };
        case DOMAINS_STATE.GET_TYPES_LIST_SUCCESS:
            return {
                ...state,
                isLoadingTypeList: false,
                isErrorTypeList: false,
                errorMessageTypeList: null,
                types: action.data
            };
        case DOMAINS_STATE.GET_TYPES_LIST_FAILED:
            return {
                ...state,
                isLoadingTypeList: false,
                isErrorTypeList: true,
                errorMessageTypeList: "something went wrong"
            };
        case DOMAINS_STATE.UPDATE_DOMAINS:
            return {
                ...state,
                isLoadingUpdate: true,
                isErrorUpdate: false,
                isSuccessUpdate: false,
                updateErrorMessage: null
            };
        case DOMAINS_STATE.UPDATE_DOMAINS_SUCCESS:
            return {
                ...state,
                isLoadingUpdate: false,
                isErrorUpdate: false,
                isSuccessUpdate: true,
                updateErrorMessage: null
            };
        case DOMAINS_STATE.UPDATE_DOMAINS_FAILED:
            return {
                ...state,
                isLoadingUpdate: false,
                isErrorUpdate: true,
                isSuccessUpdate: false,
                updateErrorMessage: action.err
            };
        case DOMAINS_STATE.UPDATE_DOMAINS_CLEANUP:
            return {
                ...state,
                isLoadingUpdate: false,
                isErrorUpdate: false,
                isSuccessUpdate: false,
                updateErrorMessage: null
            };
        default:
            return state;
    }
};

export default DomainsReducer;