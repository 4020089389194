import { LOGIN_STATE } from './action';

const initialState = {
	isLoading: false,
	isSuccess: false,
	isError: false,
	errorMessage: null,
	errorObject: {},

	isLoadingCheckEmail: false,
	isSuccessCheckEmail: false,

	token: '',
	isRequired: false,

	user: null,
	secureCredential: null,

	isLoadingPermission: false,
	isSuccessPermission: false,
	isErrorPermission: false,
	permission: {},

	fullname: '',

	isSignOut: false,
	isLoadingLogout: false,
	isExpired: false,
	accessToken: null,
  refreshToken: null,
};

const AuthReducer = (state = initialState, action) => {
	switch (action.type) {
		case LOGIN_STATE.CHECK_EMAIL_LOGIN:
			return {
				...state,
				isLoadingCheckEmail: true,
				isSuccessCheckEmail: false,
				isError: false,
				errorMessage: null,
				errorObject: {}
			};
		case LOGIN_STATE.CHECK_EMAIL_LOGIN_FAILED:
			return {
				...state,
				isLoadingCheckEmail: false,
				isSuccessCheckEmail: false,
				isError: true,
				errorMessage: action?.data?.message ?? 'Something went wrong',
				errorObject: action.data
			};
		case LOGIN_STATE.CHECK_EMAIL_LOGIN_SUCCESS:
			return {
				...state,
				isLoadingCheckEmail: false,
				isSuccessCheckEmail: true,
				isError: false,
				errorMessage: null,
				errorObject: {},
				isSignOut: false,
				isExpired: false,
			};

		case LOGIN_STATE.FETCH_LOGIN:
			return {
				...state,
				isLoading: true,
				isSuccess: false,
				isError: false,
				isRequired: false,
				errorMessage: null,
				errorObject: {},
				isLoadingLogout: false,
			};
		case LOGIN_STATE.FETCH_LOGIN_FAILED:
			return {
				...state,
				isLoading: false,
				isSuccess: false,
				isError: true,
				isRequired: false,
				errorMessage: action?.data?.message ?? 'Something went wrong',
				errorObject: action.data,
				isLoadingLogout: false,
			};
		case LOGIN_STATE.FETCH_LOGIN_SUCCESS:
			return {
				...state,
				isLoading: false,
				isSuccess: true,
				isError: false,
				errorMessage: null,
				errorObject: {},
				isRequired: false,
				token: action.data.token,
				isLoadingLogout: false,
				isExpired: false,
			};
		case LOGIN_STATE.REQUIRED_PASSWORD:
			return {
				...state,
				isLoading: false,
				isSuccess: true,
				isError: false,
				errorMessage: null,
				isRequired: true,
				token: action.data.tokenTemp,
				user: action.data.user,
				secureCredential: action.data.credential
			};

		case LOGIN_STATE.GET_PERMISSION:
			return {
				...state,
				isLoadingPermission: true,
				isSuccessPermission: false,
				isErrorPermission: false,
				errorMessage: null,
			};
		case LOGIN_STATE.GET_PERMISSION_FAILED:
			return {
				...state,
				isLoadingPermission: false,
				isSuccessPermission: false,
				isErrorPermission: true,
				errorMessage: action?.data?.message ?? 'Something went wrong',
			};
		case LOGIN_STATE.GET_PERMISSION_SUCCESS:
			return {
				...state,
				isLoadingPermission: false,
				isSuccessPermission: true,
				isErrorPermission: false,
				errorMessage: null,
				permission: action.data.data,
			};
		case LOGIN_STATE.FETCH_LOGOUT_LOADER:
			return {
				...state,
				isLoadingLogout: true
			};
		case LOGIN_STATE.FETCH_LOGOUT:
			return initialState;
			// return {
			// 	...state,
			// 	token: '',
			// 	isSignOut: true
			// };

		case LOGIN_STATE.CLEAR_NEW_PASSWORD_STATE:
			return {
				...state,
				isLoading: false,
				isSuccess: false,
				isError: false,
				errorMessage: null,
				errorObject: {},
			};
		case LOGIN_STATE.CLEAR_LOADER_PERMISSION:
			return {
				...state,
				isLoadingPermission: false,
				isSuccessPermission: false,
				isErrorPermission: false,
			};
		case LOGIN_STATE.CLEAR_CHECK_EMAIL_STATE:
			return {
				...state,
				isLoading: false,
				isSuccess: false,
				isError: false,
				errorMessage: null,
				errorObject: {},
			};
		case LOGIN_STATE.SET_TOKEN:
			return {
				...state,
				isLoading: false,
				isSuccess: true,
				isError: false,
				errorMessage: null,
				errorObject: {},
				isRequired: false,
				isSignOut: false,
				token: action.token,
				isExpired: false,
				isLoadingLogout: false
			};
		case LOGIN_STATE.SET_ACCESS_TOKEN:
			return {
				...state,
				accessToken: action.token,
			};
		case LOGIN_STATE.SET_REFRESH_TOKEN:
			return {
				...state,
				refreshToken: action.token,
			};
		case LOGIN_STATE.SET_EXPIRED:
			return {
				...state,
				isExpired: action.isExpired
			};
		case LOGIN_STATE.LOGIN_CLEAR_STATE:
			return initialState;
		case LOGIN_STATE.CLEAR_STATE_PERMISSION:
			return {
				...state,
				isError: false,
				errorMessage: null,
				errorObject: {}
			};
		default:
			return state;
	};
};

export default AuthReducer;