import axios from 'axios';
import { Service } from 'config';

export const getListWorkflows = (params) => {
  return axios.get(`${Service.API_SERVICE}/workflows`, { params });
};

export const getOneWorkflow = (id) => {
  return axios.get(`${Service.API_SERVICE}/workflows/${id}`, {
    hideMessage: true
  });
};

export const getOperationPerRun = (params) => {
  return axios.get(`${Service.API_SERVICE}/operations`, { params });
};

export const getListRuns = (params) => {
  return axios.get(`${Service.API_SERVICE}/runs`, { params });
};

export const getOneRun = (id) => {
  return axios.get(`${Service.API_SERVICE}/runs/${id}`);
};

export const resumeRun = (id, params) => {
  return axios.post(`${Service.API_SERVICE}/runs/${id}`, null, {
    params: {
      ...params
    }
  });
};