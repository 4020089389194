import { forEach, orderBy, sortBy } from "lodash";
import { WORKFLOWS } from "services";

export const WORKFLOWS_STATE = {
  RESUME_RUN: 'RESUME_RUN',
  RESUME_RUN_FAILED: 'RESUME_RUN_FAILED',
  RESUME_RUN_SUCCESS: 'RESUME_RUN_SUCCESS',
  SET_PAGE_SIZE_RUN: 'SET_PAGE_SIZE_RUN',
  SET_PAGE_SIZE_WORKFLOW: 'SET_PAGE_SIZE_WORKFLOW',
  SET_ACTIVE_TAB: 'SET_ACTIVE_TAB',
  GET_LIST_WORKFLOWS: 'GET_LIST_WORKFLOWS',
  GET_LIST_WORKFLOWS_FAILED: 'GET_LIST_WORKFLOWS_FAILED',
  GET_LIST_WORKFLOWS_SUCCESS: 'GET_LIST_WORKFLOWS_SUCCESS',

  GET_ONE_WORKFLOW: 'GET_ONE_WORKFLOW',
  GET_ONE_WORKFLOW_FAILED: 'GET_ONE_WORKFLOW_FAILED',
  GET_ONE_WORKFLOW_SUCCESS: 'GET_ONE_WORKFLOW_SUCCESS',

  GET_RUNS: 'GET_RUNS',
  GET_RUNS_FAILED: 'GET_RUNS_FAILED',
  GET_RUNS_SUCCESS: 'GET_RUNS_SUCCESS',
  GET_ONE_RUN: 'GET_ONE_RUN',
  GET_ONE_RUN_FAILED: 'GET_ONE_RUN_FAILED',
  GET_ONE_RUN_SUCCESS: 'GET_ONE_RUN_SUCCESS',

  GET_ONE_RUN: 'GET_ONE_RUN',
  GET_ONE_RUN_FAILED: 'GET_ONE_RUN_FAILED',
  GET_ONE_RUN_SUCCESS: 'GET_ONE_RUN_SUCCESS',

  GET_OPERATION_PER_RUN: 'GET_OPERATION_PER_RUN',
  GET_OPERATION_PER_RUN_FAILED: 'GET_OPERATION_PER_RUN_FAILED',
  GET_OPERATION_PER_RUN_SUCCESS: 'GET_OPERATION_PER_RUN_SUCCESS',
  GET_LIST_WORKFLOWS_FOR_FILTER: 'GET_LIST_WORKFLOWS_FOR_FILTER',
  GET_LIST_WORKFLOWS_FOR_FILTER_FAILED: 'GET_LIST_WORKFLOWS_FOR_FILTER_FAILED',
  GET_LIST_WORKFLOWS_FOR_FILTER_SUCCESS: 'GET_LIST_WORKFLOWS_FOR_FILTER_SUCCESS',
  RESET_LIST_WORKFLOWS_FOR_FILTER: 'RESET_LIST_WORKFLOWS_FOR_FILTER',
};

export const resetWorkflowsFilter = () => ({
  type: WORKFLOWS_STATE.RESET_LIST_WORKFLOWS_FOR_FILTER
});

export const setPageSizeRun = (pageSize) => ({
  type: WORKFLOWS_STATE.SET_PAGE_SIZE_RUN,
  pageSize
});

export const setPageSizeWorkflow = (pageSize) => ({
  type: WORKFLOWS_STATE.SET_PAGE_SIZE_WORKFLOW,
  pageSize
});

export const setActiveTab = (tab) => ({
  type: WORKFLOWS_STATE.SET_ACTIVE_TAB,
  tab
});

export const getListWorkflows = (params) => {
  return dispatch => {
    dispatch(fetchListWorkflows());
    WORKFLOWS.getListWorkflows(params).then((response) => {
      const {data} = response;
      if (data.success) {
        dispatch(fetchListWorkflowsSuccess(data.data));
      }
    }).catch((error) => {
      dispatch(fetchListWorkflowsFailed(error));
    });
  };
};

export const getOneWorkflow = (id) => {
  return dispatch => {
    dispatch(fetchOneWorkflows());
    WORKFLOWS.getOneWorkflow(id).then((response) => {
      const {data} = response;
      if (data.success) {
        dispatch(fetchOneWorkflowsSuccess(data.data));
      }
    }).catch((error) => {
      dispatch(fetchOneWorkflowsFailed(error));
    });
  };
};

export const getListWorkflowsFilter = (params) => {
  return dispatch => {
    dispatch(fetchListWorkflowsFilter());
    WORKFLOWS.getListWorkflows(params).then((response) => {
      const {data} = response;
      if (data.success) {
        dispatch(fetchListWorkflowsFilterSuccess(data.data));
      }
    }).catch((error) => {
      dispatch(fetchListWorkflowsFilterFailed(error));
    });
  };
};

export const getRuns = (params) => {
  return dispatch => {
    dispatch(fetchRuns());
    WORKFLOWS.getListRuns(params).then((response) => {
      const {data} = response;
      if (data.success) {
        dispatch(fetchRunsSuccess(data.data));
      }
    }).catch((error) => {
      dispatch(fetchRunsFailed(error));
    });
  };
};

export const getOneRun = (id) => {
  return dispatch => {
    dispatch(fetchOneRun());
    WORKFLOWS.getOneRun(id).then((response) => {
      const {data} = response;
      if (data.success) {
        dispatch(fetchOneRunSuccess(data.data));
      }
    }).catch((error) => {
      dispatch(fetchOneRunFailed(error));
    });
  };
};

const findChildren = (parent, collection, callback) => {
  parent.children.forEach((child) => {
    const obj = collection.find((obj) => obj.id === child)
    callback(obj);
    if(obj.children.length > 0) findChildren(obj, collection, callback)
  })
}

const arrangeOperation = (data) => {
    const map = [];
    const rootElements = data.find((obj) => obj.root);
    map.push(rootElements);

    if(rootElements.children.length > 0) {
      findChildren(rootElements, data, (children) => {
        map.push(children)
      });
    }
    return map;
}

export const getOperationPerRun = (params) => {
  return dispatch => {
    dispatch(fetchOperationPerRun());
    WORKFLOWS.getOperationPerRun(params).then((response) => {
      const {data} = response;
      const sortedData = arrangeOperation(data?.data)
      if (data.success) {
        dispatch(fetchOperationPerRunSuccess(sortedData));
      }
    }).catch((error) => {
      dispatch(fetchOperationPerRunFailed(error));
    });
  };
};

export const resumeRun = (id, params) => {
  return dispatch => {
    dispatch(postResumeRun());
    WORKFLOWS.resumeRun(id, params).then((response) => {
      const {data} = response;
      if (data.success) {
        dispatch(postResumeRunSuccess(data.data));
      }
    }).catch((error) => {
      dispatch(postResumeRunFailed(error));
    });
  };
};

const postResumeRun = () => {
  return {
    type: WORKFLOWS_STATE.RESUME_RUN
  };
};
const postResumeRunFailed = () => {
  return {
    type: WORKFLOWS_STATE.RESUME_RUN_FAILED
  };
};
const postResumeRunSuccess = (data) => {
  return {
    type: WORKFLOWS_STATE.RESUME_RUN_SUCCESS,
    data
  };
};
const fetchListWorkflowsFilter = () => {
  return {
    type: WORKFLOWS_STATE.GET_LIST_WORKFLOWS_FOR_FILTER
  };
};
const fetchListWorkflowsFilterFailed = () => {
  return {
    type: WORKFLOWS_STATE.GET_LIST_WORKFLOWS_FOR_FILTER_FAILED
  };
};
const fetchListWorkflowsFilterSuccess = (data) => {
  return {
    type: WORKFLOWS_STATE.GET_LIST_WORKFLOWS_FOR_FILTER_SUCCESS,
    data
  };
};

const fetchListWorkflows = () => {
  return {
    type: WORKFLOWS_STATE.GET_LIST_WORKFLOWS
  };
};
const fetchListWorkflowsFailed = () => {
  return {
    type: WORKFLOWS_STATE.GET_LIST_WORKFLOWS_FAILED
  };
};
const fetchListWorkflowsSuccess = (data) => {
  return {
    type: WORKFLOWS_STATE.GET_LIST_WORKFLOWS_SUCCESS,
    data
  };
};

const fetchOneWorkflows = () => {
  return {
    type: WORKFLOWS_STATE.GET_ONE_WORKFLOW
  };
};
const fetchOneWorkflowsFailed = () => {
  return {
    type: WORKFLOWS_STATE.GET_ONE_WORKFLOW_FAILED
  };
};
const fetchOneWorkflowsSuccess = (data) => {
  return {
    type: WORKFLOWS_STATE.GET_ONE_WORKFLOW_SUCCESS,
    data
  };
};


const fetchRuns = () => {
  return {
    type: WORKFLOWS_STATE.GET_RUNS
  };
};
const fetchRunsFailed = () => {
  return {
    type: WORKFLOWS_STATE.GET_RUNS_FAILED
  };
};
const fetchRunsSuccess = (data) => {
  return {
    type: WORKFLOWS_STATE.GET_RUNS_SUCCESS,
    data
  };
};

const fetchOneRun = () => {
  return {
    type: WORKFLOWS_STATE.GET_ONE_RUN
  };
};
const fetchOneRunFailed = () => {
  return {
    type: WORKFLOWS_STATE.GET_ONE_RUN_FAILED
  };
};
const fetchOneRunSuccess = (data) => {
  return {
    type: WORKFLOWS_STATE.GET_ONE_RUN_SUCCESS,
    data
  };
};

const fetchOperationPerRun = () => {
  return {
    type: WORKFLOWS_STATE.GET_OPERATION_PER_RUN
  };
};
const fetchOperationPerRunFailed = () => {
  return {
    type: WORKFLOWS_STATE.GET_OPERATION_PER_RUN_FAILED
  };
};
const fetchOperationPerRunSuccess = (operations) => {
  return {
    type: WORKFLOWS_STATE.GET_OPERATION_PER_RUN_SUCCESS,
    operations
  };
};