// Declaration for Service needed
const Service = {
    BASE_URL : process.env.REACT_APP_BASE_URL,
    API: process.env.REACT_APP_API_GATEWAY_URL,
    API_SERVICE: process.env.REACT_APP_SERVICES_API_GATEWAY_URL,
    AZURE_API_INVENTORY: process.env.REACT_APP_AZURE_API_GATEWAY_INVENTORY_URL,
    AUTH_API: process.env.REACT_APP_AZURE_API_AUTH_GATEWAY_URL,
    USERS_API: process.env.REACT_APP_AUTH_API,
    
    //FOR TEMPORARY USAGE ONLY! CHANGE TO USER PORTAL API ASAP
    CONTENT_API: process.env.REACT_APP_CONTENT_API 
};
export default Service;
