import { Service } from '../config';
import axios from 'axios';
import 'crypto-js/lib-typedarrays';
import { Auth } from "aws-amplify";
import { getCookie } from 'helpers';

/**
 * Signout the current the session
 * @param {Object} data 
 * @returns {Promise}
 */
export const signOut = () => {
  return axios.get(`${Service.USERS_API}/v2/signout`);
};

export const checkEmail = (param) => {
  const { username } = param;
  return axios.post(`${Service.API}/users/pre-auth-validate`, { email: username });
};

export const postLogin = (params) => {
  return Auth.signIn({
    username: params?.username?? '',
    password: params?.password?? '',
    validationData: {
      email: params?.username?? ''
    }
  });
};

export const completeNewPassword = (param) => {
  let given_name = param.user.challengeParam.userAttributes.given_name ?? param.user.username;
  let family_name = param.user.challengeParam.userAttributes.family_name ?? param.user.username;

  return Auth.completeNewPassword(
    param.user,           // the Cognito User Object
    param.password,       // the new password
    {
      given_name,
      family_name,
    }
  );
};

export const currentAuthenticatedUser = () => {
  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
  return Auth.currentAuthenticatedUser({bypassCache: false});
};

export const postLoginGoogle = () => {
  return Auth.currentSession();
};

export const postExistUser = (param) => {
  return axios.post(`${Service.API}/user-exist`, param);
};

// export function getPermission(params) {
//   return axios.get(`${Service.API}/role/actions?orgid=${params?.orgid ?? ''}`);
// };

export function getPermission(params) {
  return axios.get(`${Service.API}/users/detail`);
};

export const getMyAccount = (params) => {
  return axios.get(`${Service.USERS_API}/v1/users/me`, {
    params,
  });
};

export const postLogout = () => {
  return Auth.signOut();
};

export const storeSession = (token) => {
  return axios.post(`${Service.AUTH_API}/user/session/`, null, {
    headers: {
      Authorization: token
    }
  });
};

export const checkSession = () => {
  return axios.get(`${Service.AUTH_API}/user/session/?user_unique=${getCookie(`JX_CLIENT_${process.env.REACT_APP_MODE}`) ?? ''}`);
};

export const revokeSession = (token) => {
  return axios.post(`${Service.AUTH_API}/user/session/revoke`, { user_unique: getCookie(`JX_CLIENT_${process.env.REACT_APP_MODE}`) ?? '' }, {
    headers: {
      Authorization: token
    }
  });
};

export function checkTokenValidity(token){
  return axios.get(`${Service.AUTH_API}/login/validate`, {
    headers: {
      Authorization: token
    }
  });
}

export function validateToken(hideMessage = false){
  return axios.get(`${Service.USERS_API}/v2/validate`, {
    hideMessage: hideMessage
  });
}

export const authorizeUser = (data) => {
  return axios.post(`${Service.USERS_API}/v2/authorize`, data);
};