/* eslint-disable no-console */
/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-no-duplicate-props */
import { withRouter } from "react-router";
import { connect } from 'react-redux';
import { compose } from 'redux';
import moment from "moment";
import _ from "lodash";

import { Input } from "reactstrap";
import MultiSelect from "react-multi-select-component";
import Select from "react-select";
import CustomSelectInput from 'components/common/CustomSelectInput';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import WorkflowFilter from "pages/DataWorkflows/Runs/WorkflowFilter";
import crypto from "crypto-js";

const date_time_request = parseInt(moment().format('mm')) < 17 ? moment().subtract(1, 'hours').toDate() : moment().toDate();

export const connector = (className, mapStateToProps, mapDispatchToProps) => {
  return compose(
    connect(
      mapStateToProps,
      mapDispatchToProps
    ),
    withRouter
  )(className);
};

export function numberFormat(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export function upperFirst(string, upperAll = true) {
  return !upperAll
    ? string.charAt(0).toUpperCase() + string.substr(1)
    : string.split(' ').map(i => upperFirst(i, false)).join(' ');
}

export const delimiterComaHandler = (array) => {
  if (array.length === 0)
    return '-';
  else
    return array.map((e, i) => { return e + (array.length === parseInt(i + 1) ? '' : ', '); });
};

export const validatePassword = (value) => {
  let error;
  if (!value) {
    error = 'Please enter your password';
  } else if (value.length < 4) {
    error = 'Value must be longer than 3 characters';
  }
  return error;
};

export const validateEmail = (value) => {
  let error;
  if (!value) {
    error = 'Please enter your email address';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    error = 'Invalid email address';
  }
  return error;
};

export const xAxisGraphDate = (date, type, specificDate = null) => {
  if (type === 'hour') {
    if (specificDate) {
      return moment(specificDate).utc().subtract(date, type).set({
        'minute': 0,
        'second': 0,
        'millisecond': 0
      });
    } else {
      return moment(date_time_request).utc().subtract(date, type).set({
        'minute': 0,
        'second': 0,
        'millisecond': 0
      });
    }
  } else if (type === 'days') {
    if (specificDate) {
      return moment.utc(specificDate).subtract(date, type);
    } else {
      return moment.utc().subtract(date, type);
    }
  }
};

export const filterInput = (props) => {
  return (
    <div className="mt-2" style={{ position: 'relative' }}>
      <label style={{
        position: 'absolute',
        top: '-20px',
        fontSize: '13px',
        left: '0px',
        fontWeight: 'bold'
      }}>{props.label}</label>
      {filterInputRendering(props)}
    </div>
  );
};

export const filterSelected = (val) => {
  return (
    <div className="d-flex ml-3 mr-3" style={{ flexWrap: 'wrap' }}>
      <p className="mb-0" style={{ whiteSpace: 'nowrap', fontSize: 14, fontWeight: 'bold' }}>{val.label}&nbsp;</p>
      <p className="mb-0" style={{ whiteSpace: 'nowrap', fontSize: 14, marginBottom: 0, color: 'grey' }}>{labelFilterSelected(val)}</p>
    </div>
  );
};

const filterInputRendering = (props) => {
  const {
    typeInput,
    name,
    label,
    value,
    options,
    onChange,
    onMenuScrollToBottom,
    isLoading,
    className,
    isClearable,
    isSearchable,
    isDisabled,
    minDate,
    hasSelectAll = true,
    customStyle,
    placeholder,
    startDate,
    endDate
  } = props;

  let component;
  if (typeInput === 'select') {
    component = <Select
      // components={{ Input: CustomSelectInput }}
      // className="react-select"
      // classNamePrefix="react-select"
      placeholder={placeholder}
      name={name}
      label={label}
      value={value}
      options={options}
      onChange={onChange}
      isLoading={isLoading}
      isDisabled={isDisabled}
      isClearable={isClearable}
      isSearchable={isSearchable}
      styles={customStyle}
      closeMenuOnScroll={false}
      onMenuScrollToBottom={() => onMenuScrollToBottom()}
    />;
  } else if (typeInput === 'multiselect') {
    component = <MultiSelect
      className={`${isLoading ? 'multi-select disabled multi-select is-not-clear' : 'multi-select is-not-clear'}`}
      selectAllLabel="All"
      options={options}
      value={value}
      disabled={isLoading || isDisabled}
      isLoading={isLoading}
      isClearable={isClearable}
      onChange={onChange}
      hasSelectAll={hasSelectAll}
      overrideStrings={{
        allItemsAreSelected: "All"
      }}
    />;
  } else if (typeInput === 'datepicker') {
    // component = <Input
    //   type="date"
    //   className={'form-control custom-datepicker'}
    //   name={name}
    //   label={label}
    //   value={value}
    //   options={options}
    //   onChange={onChange}
    //   isLoading={isLoading}
    //   disabled={isLoading || isDisabled}
    //   onChange={onChange}
    //   isClearable={isClearable}
    //   min={moment(new Date()).format('YYYY-MM-DD')}
    // />;
    component = <DatePicker
      selected={value}
      value={value}
      popperPlacement="bottom-start"
      placeholderText="dd/mm/yyyy"
      className="custom-datepicker"
      minDate={minDate}
      onChange={onChange}
    />;
  } else if (typeInput === 'daterange') {
    component = <DatePicker
      placeholderText={placeholder}
      popperPlacement="bottom-start"
      className="custom-datepicker"
      onChange={onChange}
      selected={startDate}
      startDate={startDate}
      endDate={endDate}
      selectsStart
      selectsEnd
      selectsRange
      disabledKeyboardNavigation
      isClearable
    />;
  } else if (typeInput === "workflow") {
    component = <WorkflowFilter onChangeFilter={onChange} value={value} />
  } else {
    component = <Input
      className={className}
      name={name}
      label={label}
      value={value}
      onChange={onChange}
      disabled={isLoading || isDisabled}
      style={customStyle}
      placeholder={placeholder}
    />;
  }

  return component;
};

export const InputFilter = (props) => {
  const {
    typeInput,
    name,
    label,
    value,
    options,
    onChange,
    isLoading,
    className
  } = props;

  let component;
  if (typeInput === 'select') {
    component = <Select
      components={{ Input: CustomSelectInput }}
      className="react-select"
      classNamePrefix="react-select"
      name={name || "form-field-name"}
      label={label}
      value={value}
      options={options}
      onChange={onChange}
      isLoading={isLoading}
      isDisabled={isLoading}
    />;
  } else if (typeInput === 'multiselect') {
    component = <MultiSelect
      className={`${isLoading ? 'multi-select disabled multi-select is-not-clear' : 'multi-select is-not-clear'}`}
      selectAllLabel="All"
      options={options}
      value={value}
      disabled={isLoading}
      isLoading={isLoading}
      onChange={onChange}
      overrideStrings={{
        allItemsAreSelected: "All"
      }}
    />;
  } else {
    component = <Input
      className={className}
      name={name}
      label={label}
      value={value}
      options={options}
      onChange={onChange}
      isLoading={isLoading}
      disabled={isLoading}
    />;
  }

  return component;
};

const labelFilterSelected = (params) => {
  let result;
  if (params.name === 'date') {
    if (!params.value) {
      result = 'Not Selected';
    } else {
      result = moment(params.value).format('MMM DD, YYYY');
    }
  }
  else if (params.typeInput === 'daterange') {
    if (!params.startDate && !params.endDate) {
      result = 'Not Selected';
    } else {
      result = `${moment(params.startDate).format('MM/DD/YYYY')} - ${moment(params.endDate).format('MM/DD/YYYY')}`;
    }
  }
  else if (_.isArray(params?.value)) {
    if (params.options.length > 0 && params.options.length === params.value.length) {
      result = `[All]`;
    } else {
      result = delimiterComaHandler(params.value.map(e => e.label));
      if (result === '-') {
        result = 'Not Selected';
      } else {
        result = delimiterComaHandler(params.value.map(e => e.label)).join(',');
      }
    }

    if (result.length > 50) {
      result = result.substring(0, 50) + '...';
    }
  } else {
    result = '';
    if (params.typeInput === 'input' && _.isString(params?.value)) {
      if(params?.value?.length>0){
        result = params?.value;
      }else{
        result = '-';
      }
    } else if (_.isObject(params?.value) || !params?.value) {
      result = params?.value?.label ?? 'Not Selected';
    }
  }

  return result;
};

export const camelFormatText = (text) => {
  if (text !== '' && !_.isUndefined(text) && !_.isNull(text) && _.isString(text)) {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }
  return text;
};

export const createGraphNodes = (datas, type, options) => {
  let graph = {
    nodes: [],
    edges: []
  };
  const {nodes, edges} = graph;
  const defaultOptions = {
    shape: "box",
    root: "#fc6ff3",
    background: "#c4c4c4",
    border: "#949494",
    highlight: "#f78a2a",
    color: '#575757'
  };
  try {
    if (options) {
      if (options.background) defaultOptions.background = options.background;
      if (options.border) defaultOptions.border = options.border;
      if (options.highlight) defaultOptions.highlight = options.highlight;
      if (options.color) defaultOptions.color = options.color;
      if (options.root) defaultOptions.root = options.root;
      if (options.shape) defaultOptions.shape = options.shape;
    }

    if (datas.length > 0) {
      datas.forEach((data) => {
        const node = {};

        node['id'] = data.id;
        node['label'] = `${data.name}\n(${camelFormatText(data.type)})`;
        node['shape'] = defaultOptions.shape;
        node['color'] = _.pick(defaultOptions, ['background', 'border', 'highlight']);
        if (data.root) node['color']['background'] = defaultOptions.root;
        if (type === 'runs') {
          if (data.status) {
            const {status} = data.status;
            if (status === 'running') {
              node['color']['background'] = '#6fdcfc'; // running
            } else if (status === 'failed') {
              node['color']['background'] = '#fa5252'; // failed
            } else if (status === 'success') {
              node['color']['background'] = '#82fc6f'; // executed
            } else {
              node['color']['background'] = '#c4c4c4'; // nothing done
            }
          } else if (!data.active) {
            node['color']['background'] = '#ffffff'; // statuc not active
          }
        }
        
        if (data.children.length > 0) {
          data.children.map((x) => {
            const edge = {};
            edge['to'] = x;
            edge['from'] = data.id;
            edge['label'] = datas.find((a) => a.id === x)?.frequency ?? '';
            edge['arrows'] = { to: true };
            edge['font'] = { align: 'middle' };
            edge['color'] = _.pick(defaultOptions, ['color', 'highlight']);
            edges.push(edge);
          });
        }
        nodes.push(node);
      });
    }
    return graph;
  } catch (error) {
    console.error('Error when generating datas for graph', error);
  }
};

export const getAllParameter = (value) => {
	let paramString = value.split('?')[1];
	let queryString = new URLSearchParams(paramString);
	let obj = {};

	for (const key of queryString.keys()) {
		if (queryString.getAll(key).length > 1) {
			obj[key] = queryString.getAll(key);
		} else {
			obj[key] = queryString.get(key);
		}
	}

	return obj;
};

export const getParameterByName = (name, url) => {
	if (!url) url = window.location.href;
	name = name.replace(/[\\[\]]/g, '\\$&');
	var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
		results = regex.exec(url);
	if (!results) return null;
	if (!results[2]) return '';
	return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
};

export const getErrorMessage = (error) => {
  if (error?.code === "ERR_NETWORK" || error?.code === "ETIMEDOUT" || error?.code === "ECONNABORTED") {
    return "There was an error connecting to the server. \nPlease check your internet connection or try again later."
  }
  return (
    error?.response?.data?.meta?.message ??
    error?.response?.data?.message ??
    error?.meta?.message ??
    error?.data?.message ??
    "There was a problem while processing your request. Please try again later."
  );
};

export const generateErrorMessageCustom = (error) => {
  if (error?.code === "ERR_NETWORK" || error?.code === "ETIMEDOUT" || error?.code === "ECONNABORTED") {
    error["response"] = {
      data: {
        meta: {
          message: "There was an error connecting to the server. \nPlease check your internet connection or try again later."
        },
        message: "There was an error connecting to the server. \nPlease check your internet connection or try again later."
      }
    }
    error["message"] = "There was an error connecting to the server. \nPlease check your internet connection or try again later.";
    return error;
  }
  if (error?.response) {
    if (!error?.response?.data?.meta?.message && !error?.response?.data?.message) {
      error["response"] = {
        ...error["response"],
        data: {
          ...error["response"]["data"],
          message: "There was a problem while processing your request.\nPlease try again later.",
          meta: {
            ...error["response"]["data"]["meta"],
            message: "There was a problem while processing your request.\nPlease try again later."
          }
        }
      }
    }
  }
  return error;
};

export const getDomain = () => {
  const hostname = window.location.hostname;
  const domain = hostname.match(
    /^(?:.*?\.)?([a-zA-Z0-9\-_]{3,}\.(?:\w{2,8}|\w{2,4}\.\w{2,4}))$/
  );
  if (domain && domain[1]) {
    return domain[1];
  }
  return "localhost";
};

// crypto
export const prune = (iv, key, data) => {
  const keyHex = crypto.enc.Base64.parse(key);
  const ivHex = crypto.enc.Base64.parse(iv);
  const bytes = crypto.AES.decrypt(data, keyHex, { iv: ivHex });
  const originalText = bytes.toString(crypto.enc.Utf8);
  return originalText;
};