/* eslint-disable array-callback-return */
import { ORGANIZATION } from "services";

export const ORGANIZATION_STATE = {
  STORE_ORG_ID: 'STORE_ORG_ID',

  GET_ORGANIZATION_USER: 'GET_ORGANIZATION_USER',
  GET_ORGANIZATION_USER_FAILED: 'GET_ORGANIZATION_USER_FAILED',
  GET_ORGANIZATION_USER_SUCCESS: 'GET_ORGANIZATION_USER_SUCCESS',
};

export const postOrganization = (orgid) => {
  return {
    type: ORGANIZATION_STATE.STORE_ORG_ID,
    orgid
  };
};

export const getOrganizationUser = (params) => {
  return dispatch => {
    dispatch(fetchOrganizationUser());
    ORGANIZATION.getOrganisationUser(params).then(response => {
      let data = response.data;
      if (data?.meta?.status) {
        dispatch(fetchOrganizationUserSuccess(data));
      } else {
        dispatch(fetchOrganizationUserFailed(data));

      }
    }).catch(error => {
      dispatch(fetchOrganizationUserFailed(error));
    });
  };
};

const fetchOrganizationUser = () => {
  return {
    type: ORGANIZATION_STATE.GET_ORGANIZATION_USER
  };
};

const fetchOrganizationUserFailed = (data) => {
  return {
    type: ORGANIZATION_STATE.GET_ORGANIZATION_USER_FAILED,
    data
  };
};

const fetchOrganizationUserSuccess = (data) => {
  let dataTemp = [];
  data.data.map(e => {
    dataTemp.push({
      label: e.name,
      value: e.id,
      external_id: e.external_id
    });
  });
  return {
    type: ORGANIZATION_STATE.GET_ORGANIZATION_USER_SUCCESS,
    data: dataTemp
  };
};
