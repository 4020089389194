import { WORKFLOWS_STATE } from "./action";

const initialState = {
  activeTab: 'workflows',
  isResumeSucced: false,
  isError: false,
  errorMessage: null,

  isLoadingWorkflowsFilter: false,
  workflowsFilter: [],
  totalWorkflowsFilter: 0,
  currentPageWorkflowFilter: 1,
  lastPageWorkflowFilter: 1,

  isLoading: false,
  workflows: [],
  currentPageWorkflow: 1,
  totalWorkflowsData: 0,
  totalWorkflowsPerPage: 10,
  lastPageWorkflow: 1,

  isLoadingGraphRun: false,
  operationPerRun: [],

  isLoadingOneWorkflow: false,
  workflow_detail: {},

  isLoadingRun: false,
  isLoadingOneRun: false,
  runsData: [],

  run_detail: {},


  currentPageRun: 1,
  totalRunsData: 0,
  totalRunsPerPage: 10,
  lastPageRun: 1
};

const WorkflowsReducer = (state = initialState, action) => {
  switch (action.type) {
    case WORKFLOWS_STATE.RESET_LIST_WORKFLOWS_FOR_FILTER:
      return {
        ...state,
        workflowsFilter: [],
        totalWorkflowsFilter: 0,
        currentPageWorkflowFilter: 1,
        lastPageWorkflowFilter: 1,
      };
    case WORKFLOWS_STATE.SET_PAGE_SIZE_RUN:
      return {
        ...state,
        totalRunsPerPage: action.pageSize,
      };
    case WORKFLOWS_STATE.SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.tab,
      };
    case WORKFLOWS_STATE.RESUME_RUN:
      return {
        ...state,
        isLoading: true,
        isError: false,
        errorMessage: null,
      };
    case WORKFLOWS_STATE.RESUME_RUN_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        errorMessage: action?.data?.message ?? "Something went wrong",
      };
    case WORKFLOWS_STATE.RESUME_RUN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        errorMessage: null,
        isResumeSucced: true,
      };
    case WORKFLOWS_STATE.GET_LIST_WORKFLOWS_FOR_FILTER:
      return {
        ...state,
        isLoadingWorkflowsFilter: true,
        isError: false,
        errorMessage: null,
      };
    case WORKFLOWS_STATE.GET_LIST_WORKFLOWS_FOR_FILTER_FAILED:
      return {
        ...state,
        isLoadingWorkflowsFilter: false,
        isError: true,
        errorMessage: action?.data?.message ?? "Something went wrong",
      };
    case WORKFLOWS_STATE.GET_LIST_WORKFLOWS_FOR_FILTER_SUCCESS:
      const tempList = [];
      action.data.data.forEach((e) => {
        tempList.push({
          value: e.id,
          label: e.name,
        });
      });
      return {
        ...state,
        isLoadingWorkflowsFilter: false,
        isError: false,
        errorMessage: null,
        workflowsFilter: state.workflowsFilter.concat(tempList),
        totalWorkflowsFilter: Number(action.data.total),
        currentPageWorkflowFilter: Number(action.data.current_page),
        lastPageWorkflowFilter: Number(action.data.last_page),
      };
    case WORKFLOWS_STATE.GET_LIST_WORKFLOWS:
      return {
        ...state,
        isLoading: true,
        isError: false,
        errorMessage: null,
      };
    case WORKFLOWS_STATE.GET_LIST_WORKFLOWS_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        errorMessage: action?.data?.message ?? "Something went wrong",
      };
    case WORKFLOWS_STATE.GET_LIST_WORKFLOWS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        errorMessage: null,
        workflows: action.data.data,
        totalWorkflowsData: Number(action.data.total),
        totalWorkflowsPerPage: Number(action.data.per_page),
        currentPageWorkflow: Number(action.data.current_page),
        lastPageWorkflow: Number(action.data.last_page),
      };
    case WORKFLOWS_STATE.GET_RUNS:
      return {
        ...state,
        isLoadingRun: true,
        isError: false,
        errorMessage: null,
      };
    case WORKFLOWS_STATE.GET_RUNS_FAILED:
      return {
        ...state,
        isLoadingRun: false,
        isError: true,
        errorMessage: action?.data?.message ?? "Something went wrong",
      };
    case WORKFLOWS_STATE.GET_RUNS_SUCCESS:
      return {
        ...state,
        isLoadingRun: false,
        isError: false,
        errorMessage: null,
        runsData: action.data.data,
        totalRunsData: Number(action.data.total),
        totalRunsPerPage: Number(action.data.per_page),
        currentPageRun: Number(action.data.current_page),
        lastPageRun: Number(action.data.last_page),
      };
    case WORKFLOWS_STATE.GET_OPERATION_PER_RUN:
      return {
        ...state,
        isLoadingGraphRun: true,
        isError: false,
        errorMessage: null,
      };
    case WORKFLOWS_STATE.GET_OPERATION_PER_RUN_FAILED:
      return {
        ...state,
        isLoadingGraphRun: false,
        isError: true,
        errorMessage: action?.data?.message ?? "Something went wrong",
      };
    case WORKFLOWS_STATE.GET_OPERATION_PER_RUN_SUCCESS:
      return {
        ...state,
        isLoadingGraphRun: false,
        isError: false,
        errorMessage: null,
        operationPerRun: action.operations,
      };

    case WORKFLOWS_STATE.GET_ONE_WORKFLOW:
      return {
        ...state,
        isLoadingOneWorkflow: true,
        isError: false,
        errorMessage: null,
      };
    case WORKFLOWS_STATE.GET_ONE_WORKFLOW_FAILED:
      return {
        ...state,
        isLoadingOneWorkflow: false,
        isError: true,
        errorMessage: action?.data?.message ?? "Something went wrong",
      };
    case WORKFLOWS_STATE.GET_ONE_WORKFLOW_SUCCESS:
      return {
        ...state,
        isLoadingOneWorkflow: false,
        isError: false,
        errorMessage: null,
        workflow_detail: action.data,
      };
    case WORKFLOWS_STATE.GET_ONE_RUN:
      return {
        ...state,
        isLoadingOneRun: true,
        isError: false,
        errorMessage: null,
      };
    case WORKFLOWS_STATE.GET_ONE_RUN_FAILED:
      return {
        ...state,
        isLoadingOneRun: false,
        isError: true,
        errorMessage: action?.data?.message ?? "Something went wrong",
      };
    case WORKFLOWS_STATE.GET_ONE_RUN_SUCCESS:
      return {
        ...state,
        isLoadingOneRun: false,
        isError: false,
        errorMessage: null,
        run_detail: action.data,
      };
    default:
      return state;
  }
};

export default WorkflowsReducer;
