import { SET_ENV_VARIABLES } from '../actions';

const INIT_STATE = {
  env: {}
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_ENV_VARIABLES:
      return { ...state, env: action.payload };
    default:
      return { ...state };
  }
};