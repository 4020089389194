import { LOGIN_STATE } from 'pages/Login/action';
import { ORGANIZATION_STATE } from './action';

const initialState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  errorMessage: null,

  orgUserOptions: null,

  orgid: null
};

const OrganizationReducer = (state = initialState, action) => {
  switch (action.type) {
    case ORGANIZATION_STATE.STORE_ORG_ID:
      return {
        ...state,
        orgid: action.orgid
      };

    case ORGANIZATION_STATE.GET_ORGANIZATION_USER:
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        isError: false,
        errorMessage: null
      };
    case ORGANIZATION_STATE.GET_ORGANIZATION_USER_FAILED:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        errorMessage: action?.data?.message ?? 'Something went wrong'
      };
    case ORGANIZATION_STATE.GET_ORGANIZATION_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        isError: false,
        errorMessage: null,
        orgUserOptions: action.data
      };

    case LOGIN_STATE.FETCH_LOGOUT:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: false,
        errorMessage: null,

        orgUserOptions: null,

        orgid: null
      };
    default:
      return state;
  };
};

export default OrganizationReducer;