/* eslint-disable no-unused-vars */
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import storage from 'redux-persist/es/storage';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import thunk from 'redux-thunk';
import { persistCombineReducers, persistStore } from 'redux-persist';
import { composeWithDevTools } from 'redux-devtools-extension';

import { rootReducer, Interceptor } from "./config";
import reportWebVitals from './reportWebVitals';

import './assets/css/main.css';

const encryptor = encryptTransform({
  secretKey: 'primary_admin_portal',
  onError: function (error) {

  }
});

const persistConfig = {
  key: "primary-admin-portal",
  storage: storage,
  whitelist: [
    'AuthReducer',
    'OrganizationReducer',
  ], // Remember Reducer which wont be clear event if you refresh the page
  transforms: [encryptor]
};

let persistReducer = persistCombineReducers(persistConfig, rootReducer);

// use applyMiddleware to add the thunk middleware to the store
let store;
let persistor;
let dev;

// If application is set to DEV then it can show debugger
// else it will disbale all debug mode
if (process.env.REACT_APP_MODE === 'LOCAL') {
  store = createStore(
    persistReducer,
    composeWithDevTools(applyMiddleware(thunk))
  );
} else {
  store = createStore(persistReducer, applyMiddleware(thunk));
}

persistor = persistStore(store);

//Interceptor Configuration (handling session)
Interceptor.requestInterceptors(store);
Interceptor.responseInterceptors(store);

const App = React.lazy(() => import(/* webpackChunkName: "App" */ './App'));

const Main = () => {
  return (
    <Provider store={store}>
      <Suspense fallback={<div className="loading" />}>
        {dev}
        <App />
      </Suspense>
    </Provider>
  );
};

ReactDOM.render(<Main />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
