import storage from "redux-persist/es/storage";
import { encryptTransform } from "redux-persist-transform-encrypt";
import { persistReducer } from "redux-persist";

import settings from './settings/reducer';
import menu from './menu/reducer';
import authUser from './auth/reducer';
import todoApp from './todo/reducer';
import chatApp from './chat/reducer';
import surveyListApp from './surveyList/reducer';
import surveyDetailApp from './surveyDetail/reducer';
import proxy from './proxy/reducer';

const ProxyPersistConfig = {
  key: `proxyPersist`,
  storage: storage,
  whitelist: ["env"],
  transforms: [
    encryptTransform({
      secretKey: "proxyPersistKey",
      onError: (error) => {
        console.log(error);
      },
    }),
  ],
};

const reducers = {
  menu,
  settings,
  authUser,
  todoApp,
  chatApp,
  surveyListApp,
  surveyDetailApp,
  proxy: persistReducer(ProxyPersistConfig, proxy)
};

export default reducers;
