import { AUTH } from 'services'; // From Configuration Service File
import jwt_decode from 'jwt-decode';
import { Cookies } from "react-cookie";
import { setEnvVariables } from 'redux/actions';
import swal from 'sweetalert';
import { getDomain, getErrorMessage } from 'helpers';

const cookies = new Cookies();

export const LOGIN_STATE = {
  CHECK_EMAIL_LOGIN: 'CHECK_EMAIL_LOGIN',
  CHECK_EMAIL_LOGIN_FAILED: 'CHECK_EMAIL_LOGIN_FAILED',
  CHECK_EMAIL_LOGIN_SUCCESS: 'CHECK_EMAIL_LOGIN_SUCCESS',

  FETCH_LOGIN: 'FETCH_LOGIN',
  FETCH_LOGIN_FAILED: 'FETCH_LOGIN_FAILED',
  FETCH_LOGIN_SUCCESS: 'FETCH_LOGIN_SUCCESS',
  REQUIRED_PASSWORD: 'REQUIRED_PASSWORD',

  GET_PERMISSION: 'GET_PERMISSION',
  GET_PERMISSION_FAILED: 'GET_PERMISSION_FAILED',
  GET_PERMISSION_SUCCESS: 'GET_PERMISSION_SUCCESS',

  CLEAR_LOADER_PERMISSION: 'CLEAR_LOADER_PERMISSION',
  CLEAR_NEW_PASSWORD_STATE: 'CLEAR_NEW_PASSWORD_STATE',
  CLEAR_CHECK_EMAIL_STATE: 'CLEAR_CHECK_EMAIL_STATE',
  CLEAR_STATE_PERMISSION: 'CLEAR_STATE_PERMISSION',

  FETCH_LOGOUT: 'FETCH_LOGOUT',

  SET_TOKEN: 'SET_TOKEN',
  SET_ACCESS_TOKEN: 'SET_ACCESS_TOKEN',
  SET_REFRESH_TOKEN: 'SET_REFRESH_TOKEN',
  SET_EXPIRED: 'SET_EXPIRED',

  LOGIN_CLEAR_STATE: 'LOGIN_CLEAR_STATE',
  FETCH_LOGOUT_LOADER: 'FETCH_LOGOUT_LOADER'
};

export const checkEmail = (params) => {
  return dispatch => {
    dispatch(checkingEmail());
    AUTH.checkEmail(params).then((response) => {
      let data = response.data;
      if (data.meta.code === 200) {
        dispatch(checkingEmailSuccess(data));
      } else {
        dispatch(checkingEmailFailed(data));
      }
    }).catch((err) => {
      dispatch(checkingEmailFailed(err));
    });
  };
};

export const signIn = (params) => {
  return async dispatch => {
    dispatch(fetchLogin());
    await AUTH.postLogin(params).then(user => {
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        dispatch(fetchRequiredNewPassword(user, params));
      } else {
        let data = {
          user,
          token: user.signInUserSession.idToken.jwtToken
        };

        dispatch(fetchLoginSuccess(data));
      }
    }).catch(error => {
      if (error.code === "UserLambdaValidationException") {
        error.message = error.message.replace('PreAuthentication failed with error', '');
      }
      dispatch(fetchLoginFailed(error));
    });
  };
};

export const postLoginGoogle = (user) => {
  return async (dispatch, getState) => {
    dispatch(fetchLogin());
    await AUTH.postLoginGoogle().then(res => {
      let data = {
        user,
        token: res.idToken.jwtToken
      };

      // Decoding the JWT token from cognito
      // then set the expired time to localstorage
      localStorage.setItem('signExp', jwt_decode(data.token)?.exp);
      localStorage.setItem('exp', jwt_decode(data.token)?.exp);
      dispatch(fetchLoginSuccess(data));
    }).catch(err => {
      dispatch(fetchLoginFailed({
        message: 'Something Went Wrong'
      }));
    });
  };
};

export const setToken = (token) => {
  return {
    type: LOGIN_STATE.SET_TOKEN,
    token
  };
};

export const setAccessToken = (token) => {
  return {
    type: LOGIN_STATE.SET_ACCESS_TOKEN,
    token
  };
};

export const setRefreshToken = (token) => {
  return {
    type: LOGIN_STATE.SET_REFRESH_TOKEN,
    token
  };
};

export const signOut = (shouldCallAPI = false) => {
  return async (dispatch) => {
    try {
      if (shouldCallAPI) await AUTH.signOut();
      if (process.env.NODE_ENV === "development") {
        cookies.remove(`jx_accessToken`, {
          path: "/",
          domain: getDomain(),
        });
        cookies.remove(`jx_refreshToken`, {
          path: "/",
          domain: getDomain(),
        });
        cookies.remove(`jx_accessToken_${process.env.REACT_APP_MODE}`, {
          path: "/",
          domain: getDomain(),
        });
        cookies.remove(`jx_refreshToken_${process.env.REACT_APP_MODE}`, {
          path: "/",
          domain: getDomain(),
        });
      }
      dispatch(setEnvVariables({}));
      window.location.replace(
        `${process.env.REACT_APP_BASE_URL_AUTH}?redirect_uri=${encodeURIComponent(
          window.location.origin
        )}`
      );
      dispatch(fetchLogoutTemp());
    } catch (error) {
      swal(getErrorMessage(error));
    }
  };
};

export const getPermission = () => {
  return dispatch => {
    dispatch(fetchPermission());
    AUTH.getMyAccount().then(response => {
      let data = response.data;
      if (data?.meta?.status) {
        dispatch(fetchPermissionSuccess(data));
      } else {
        dispatch(fetchPermissionFailed(data));

      }
    }).catch(error => {
      dispatch(fetchPermissionFailed(error));
    });
  };
};

export const postNewPassword = (param) => {
  return async (dispatch, getState) => {
    dispatch(fetchLogin());
    await AUTH.postLogin(param.secureCredential).then(async (user) => {
      await AUTH.completeNewPassword(param);
      await AUTH.currentAuthenticatedUser().then(session => {
        let data = {
          user,
          token: session.signInUserSession.idToken.jwtToken
        };
        dispatch(fetchLoginFailed(data));
      }).catch(err => {
        
      });
    }).catch((error) => {
      dispatch(fetchLoginFailed(error));
    });
  };
};

export const clearState = () => {
  return {
    type: LOGIN_STATE.LOGIN_CLEAR_STATE
  };
};

export const clearLoaderPermission = () => {
  return {
    type: LOGIN_STATE.CLEAR_LOADER_PERMISSION
  };
};

export const clearNewPasswordState = () => {
  return {
    type: LOGIN_STATE.CLEAR_NEW_PASSWORD_STATE
  };
};

export const clearCheckEmailState = () => {
  return {
    type: LOGIN_STATE.CLEAR_CHECK_EMAIL_STATE
  };
};

const checkingEmail = () => {
  return {
    type: LOGIN_STATE.CHECK_EMAIL_LOGIN
  };
};

const checkingEmailFailed = (data) => {
  return {
    type: LOGIN_STATE.CHECK_EMAIL_LOGIN_FAILED,
    data
  };
};

const checkingEmailSuccess = (data) => {
  return {
    type: LOGIN_STATE.CHECK_EMAIL_LOGIN_SUCCESS,
    data
  };
};

const fetchLogin = () => {
  return {
    type: LOGIN_STATE.FETCH_LOGIN
  };
};

const fetchLoginFailed = (data) => {
  return {
    type: LOGIN_STATE.FETCH_LOGIN_FAILED,
    data
  };
};

const fetchLoginSuccess = (data) => {
  return {
    type: LOGIN_STATE.FETCH_LOGIN_SUCCESS,
    data
  };
};

const fetchRequiredNewPassword = (user, credential) => {
  let tokenTemp = user.Session;
  return {
    type: LOGIN_STATE.REQUIRED_PASSWORD,
    data: {
      user,
      tokenTemp,
      credential
    }
  };
};

const fetchLogoutTemp = () => {
  return {
    type: LOGIN_STATE.FETCH_LOGOUT
  };
};

const fetchPermission = () => {
  return {
    type: LOGIN_STATE.GET_PERMISSION
  };
};

const fetchPermissionFailed = (data) => {
  return {
    type: LOGIN_STATE.GET_PERMISSION_FAILED,
    data
  };
};

const fetchPermissionSuccess = (data) => {
  return {
    type: LOGIN_STATE.GET_PERMISSION_SUCCESS,
    data
  };
};

const fetchLogoutLoader = () => {
  return {
    type: LOGIN_STATE.FETCH_LOGOUT_LOADER
  };
};

export const clearStatePermission = () => {
  return {
    type: LOGIN_STATE.CLEAR_STATE_PERMISSION
  };
};

export const setExpired = (isExpired = false) => {
  return {
    type: LOGIN_STATE.SET_EXPIRED,
    isExpired
  };
};
