import axios from "axios";
import { Service } from "config";
import { setAccessToken, setRefreshToken, signOut } from "pages/Login/action";
import swal from "sweetalert";
import moment from "moment";
import { generateErrorMessageCustom, getCookie, getErrorMessage } from "helpers";
import { Cookies } from "react-cookie";
import { AUTH } from "services";

const cookies = new Cookies();
let isRefreshing = false;
let failedQueue = [];

axios.defaults.withCredentials = true;

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

const requestInterceptors = (store) => {
  // Add a request interceptor
  axios.interceptors.request.use(
    async (request) => {
      let accessToken = cookies.get(
        `jx_accessToken_${process.env.REACT_APP_MODE}`
      );
      // if (
      //   request.url.includes("/user/") ||
      //   request.url.includes("/content/") ||
      //   request.url.includes("/video/") ||
      //   request.url.includes("/validate") ||
      //   request.url.includes("/users/me")
      // )
      if (store.getState().proxy?.env?.ADMIN_APP_KEY) {
        request.headers["JIXIE_APP_KEY"] =
          store.getState().proxy?.env?.ADMIN_APP_KEY;
      }

      // if (request.url.includes(`${Service.USERS_API}`)) {
      if (accessToken && process.env.NODE_ENV === "development")
        request.headers["Authorization"] = accessToken;
      // }

      const APIKey = store.getState().AuthReducer?.permission?.apikey;
      if (APIKey) {
        request.headers["key"] = APIKey;
        if (request.url.includes(`${Service.CONTENT_API}`)) {
          request.headers["JIXIE_USER_KEY"] = APIKey;
        }
      }
      return request;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

const responseInterceptors = (store) => {
  // Add a response handler using interceptor
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const response = error.response;
      const originalConfig = error?.config;
      let refreshToken = undefined;
      if (process.env.NODE_ENV === "development") {
        refreshToken = cookies.get(
          `jx_refreshToken_${process.env.REACT_APP_MODE}`
        );
      }

      if (response) {
        const data = response.data;
        if (
          response.status === 401 ||
          (response.status === 403 &&
            (data?.message?.toLowerCase()?.includes("token") ||
              data?.meta?.message?.toLowerCase()?.includes("token") ||
              data?.message?.toLowerCase()?.includes("unauthorized") ||
              data?.meta?.message?.toLowerCase()?.includes("unauthorized")) &&
            !originalConfig?._retry)
        ) {
          const handleRefreshToken = () => {
            if (isRefreshing) {
              if (response?.config?.url?.includes("/v2/authorize")) {
                store.dispatch(signOut(true));
                return;
              }
              return new Promise(function (resolve, reject) {
                failedQueue.push({ resolve, reject });
              })
                .then((token) => {
                  if (process.env.NODE_ENV === "development") {
                    originalConfig.headers["Authorization"] = token;
                  }
                  return axios(originalConfig);
                })
                .catch((err) => {
                  return Promise.reject(err);
                });
            }
            originalConfig._retry = true;
            isRefreshing = true;

            return new Promise(function (resolve, reject) {
              let params = {
                grant_type: "REFRESH_TOKEN",
              };
              if (process.env.NODE_ENV === "development") {
                params["refresh_token"] = refreshToken;
              }
              AUTH.authorizeUser(params)
                .then((resp) => {
                  if (resp?.data) {
                    const { data: refreshTokenResult } = resp;
                    if (refreshTokenResult?.meta?.status) {
                      if (process.env.NODE_ENV === "development") {
                        cookies.set(
                          `jx_accessToken_${process.env.REACT_APP_MODE}`,
                          refreshTokenResult.data.accessToken,
                          {
                            path: "/",
                            expires: moment().add(1, "year").toDate(),
                            domain: process.env.REACT_APP_DOMAIN_COOKIE,
                          }
                        );
                        cookies.set(
                          `jx_refreshToken_${process.env.REACT_APP_MODE}`,
                          refreshTokenResult.data.refreshToken,
                          {
                            path: "/",
                            expires: moment().add(1, "year").toDate(),
                            domain: process.env.REACT_APP_DOMAIN_COOKIE,
                          }
                        );
                      }
                      store.dispatch(
                        setAccessToken(refreshTokenResult.data.accessToken)
                      );
                      store.dispatch(
                        setRefreshToken(refreshTokenResult.data.refreshToken)
                      );
                      if (process.env.NODE_ENV === "development") {
                        axios.defaults.headers.common["Authorization"] =
                          refreshTokenResult.data.accessToken;
                        originalConfig.headers = {
                          ...originalConfig.headers,
                          Authorization: refreshTokenResult.data.accessToken,
                        };
                      }
                      processQueue(null, refreshTokenResult.data.accessToken);
                      resolve(axios(originalConfig));
                    }
                  }
                })
                .catch((error) => {
                  processQueue(error, null);
                  // reject(error);
                  store.dispatch(signOut(true));
                })
                .then(() => {
                  isRefreshing = false;
                });
            });
          };
          if (process.env.NODE_ENV === "development") {
            if (refreshToken) {
              return await handleRefreshToken();
            } else {
              store.dispatch(signOut(true));
              return;
            }
          } else {
            return await handleRefreshToken();
          }
          // return Promise.reject(error);
        } else {
          console.log("response", response);
          if (
            response?.config?.hideMessage &&
            Boolean(response?.config?.hideMessage)
          ) {
            return;
          } else if (
            response.status === 422 &&
            response?.config?.url?.includes("/v2/authorize")
          ) {
            store.dispatch(signOut(true));
            return;
          } else swal(getErrorMessage(error));
        }
      }

      if (axios.isCancel(error)) {
        return error;
      }
      swal({ icon: "error", text: getErrorMessage(error) });
      return Promise.reject(generateErrorMessageCustom(error));
    }
  );
};

const interceptor = {
  requestInterceptors,
  responseInterceptors,
};

export default interceptor;
